import React from 'react';
import styled from 'styled-components';
import { background, color, layout, typography } from "../../shared/styles"
import Section from "../../layout/Section"
import ColumnLayout from "../../layout/ColumnLayout"
import HowItWorksHeroPNG from "../../../assets/how-it-works-hero.png"
import AppStoreBadgeSVG from "../../../assets/app_store_badge.svg"
import PlayBadgeSVG from "../../../assets/play_badge.svg"
import FeatureCardSVG from "../../../assets/feature/card.svg"
import CardSVG from "../../../assets/feature/card.svg"

const HeroTitle = styled.section`
  padding-left: ${layout.columnPaddingNormal}px;
  
  text-align: center;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    text-align: left;
    padding-right: 70px;
  }
  z-index: 2;
`;

const Title = styled.h1`
  font-weight: ${typography.weight.bold};
  font-size: 38px;
  line-height: 1.2;
  margin-top: 80px;
  margin-bottom: 16px;
`;

const Subtitle = styled.div`
  font-size: ${typography.size.p1}px;
  line-height: 1.5;
  color: ${color.textSecondary};
  margin-bottom: 1rem;
`;

const AppStoreBadges = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: center;

  @media (min-width: ${layout.viewportWidthMedium}px) {
    justify-content: flex-start;
  }

  @media (min-width: ${layout.viewportWidthSmall}px) {
    flex-direction: row;
    svg + svg {
      margin-left: 14px;
    }
  }
`;

const HeroGraphicBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  width: 574px;
  height: 369px;
  background-image: url(${HowItWorksHeroPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const HeroGraphic = styled.div`
  position: relative;
  z-index: 1;
  
  user-select: none;
  
  height: 354px;
  text-align: center;
`;

export default function Hero() {
  return (
    <>
      <Section>
        <ColumnLayout columns="1,1">
          <HeroTitle>
            <Title>Introducing the Splyt Card</Title>
            <Subtitle>
              The Splyt card is a free debit card with no hidden charges.{/* Get cash back and exclusive rewards by using the card at select merchants.*/} Tap on the charge to split with friends.
            </Subtitle>
            <AppStoreBadges>
              <AppStoreBadgeSVG />
              <PlayBadgeSVG />
            </AppStoreBadges>
          </HeroTitle>
          <HeroGraphic>
            <FeatureCardSVG width="412" height="354"/>
          </HeroGraphic>
        </ColumnLayout>
      </Section>
    </>
  );
}