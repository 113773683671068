import React from 'react';
import Footer from '../../layout/Footer';
import { background, color, typography } from '../../shared/styles';
import ValueProp from '../../layout/ValueProp';
import Hero from './Hero';
import Section from "../../layout/Section"
import CardReaderSVG from "../../../assets/card-reader.svg"
import CardPaidBackSVG from "../../../assets/card-paid-back.svg"
import CardSplitBillsSVG from "../../../assets/card-split-bills.svg"
import CardMobilePaySVG from "../../../assets/card-mobile-pay.svg"
import CardMapSVG from "../../../assets/card-map.svg"
import CardNotificationSVG from "../../../assets/card-notification.svg"
import CardATMAccessSVG from "../../../assets/card-atm-access.svg"
import SplitIconSVG from "../../../assets/split-icon.svg"
import ColumnLayout from "../../layout/ColumnLayout"
import Benefit from "../../layout/Benefit"

export default function CardScreen() {
  return (
    <>
      <Hero />
      <Section backgroundColor={background.bg}>
        <ColumnLayout columns="1,1,1">
          <Benefit color="#0EA480" backgroundColor="#E9F5F4" title="No Hidden Fees" description="It cost nothing to use the Splyt card in fact you end up saving by using rewards.">$0</Benefit>
          <Benefit color="#8370F4" backgroundColor="#F1F1FC" title="No Interest" description="Since it's a debit card there's no credit checks or debt to accumulate.">0%</Benefit>
          <Benefit color="#F18443" backgroundColor="#F8F3F0" title="Spending Control" description="Since it's a debit card there's no credit checks or debt."><SplitIconSVG/></Benefit>
        </ColumnLayout>
      </Section>
      {/*
      <Section>
        <ValueProp
          orientation="right"
          media={<CardReaderSVG/>}
          title="Automatic Rewards"
          desc="Save automatically with instant cashback. Rewards are automatically applied to your purchase and everyone benefits from the savings."
        />
      </Section>*/}
      <Section>
        <ValueProp
          orientation="left"
          media={<CardPaidBackSVG/>}
          title="Get Paid Back Instantly"
          desc="Getting paid back is fast, easy, and transparent for everyone. Make group purchases with the split card and get paid back."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="right"
          media={<CardSplitBillsSVG/>}
          title="Automatically Split Bills"
          desc="Use the Splyt Card for recurring bills and the charges are automatically split among your friends. No need to ask to get paid back everytime a bill is due."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="left"
          media={<CardMobilePaySVG/>}
          title="Apply Pay + Google Pay"
          desc="Add your Splyt card to Apple Pay or Google Pay and get started instantly. No need to wait for your card to arrive or carry it around."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="right"
          media={<CardMapSVG/>}
          title="Shop Anywhere"
          desc="Use your Splyt Card anywhere the card is accepted, which is practically anywhere. It even supports multiple currencies so you can use it worldwide."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="left"
          media={<CardNotificationSVG/>}
          title="Full Spending Control"
          desc="Control your card in real-time by enabling or disabling it anytime in the app."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="right"
          media={<CardATMAccessSVG/>}
          title="ATM Access"
          desc="Withdraw from over 7500 cost-free ATMs in the US or pay standard ATM fees elsewhere."
        />
      </Section>
      <Footer />
    </>
  );
}
